
.booth-ad-content {
    height: 100%;
    background-color: #f7f9fb;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .header {
        margin: 0 20px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #343434;
    }
    .area-header {
        display: flex;
        align-items: flex-end;
        margin: 20px;
        .focus-text {
            font-size: 14px;
            color: #343434;
            margin-left: 13px;
            font-weight: 400;
            .focus-city {
                font-size: 14px;
                color: #FF0000;
            }
        }
    }
    .chart-tab {
        margin: 20px 40px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background: #fff;
        .chart-tab-header {
            font-size: 16px;
            color: #FD4446;
            line-height: 1;
            display: flex;
            span {
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -10px;
                    height: 2px;
                    background: #FD4446;
                }
            }
        }
        .chart-tab-title {
            margin: 35px 0 20px;
            font-size: 19px;
            line-height: 1;
            font-size: 18px;
            color: #333;
            font-weight: 600;
        }
        .tab-title {
            // padding-left: 15px;
            display: flex;
            align-items: center;
            // position: relative;
            // &:before {
            //     content: '';
            //     position: absolute;
            //     top: -2px;
            //     left: 0;
            //     width: 5px;
            //     height: 20px;
            //     background: #2DC079;
            //     border-radius: 3px;
            // }
            .popover-title {
                font-size: 18px;
                line-height: 1;
                display: inline-block;
                padding-right: 10px;
                color: #333;
                font-weight: 600;
            
            }
        }
        .data-content {
            height: 330px;
            margin-top: 30px;
        }
        // 多选框的颜色
        ::v-deep .el-checkbox-group {
            .el-checkbox {
                .el-checkbox__input {
                    .el-checkbox__inner {
                        &:hover {
                            border-color: #0083FF;
                        }
                    }
                    &.is-focus {
                        .el-checkbox__inner {
                            border-color: #0083FF;
                        }
                    }
                }
                &.is-checked {
                    .el-checkbox__input {
                        &.is-checked {
                            .el-checkbox__inner {
                                background-color: #0083FF;
                                border-color: #0083FF;
                            }
                        }
                        &:hover {
                            border-color: #0083FF;
                        }
                    }
                    .el-checkbox__label {
                        color: #343434;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    /*.area-table {*/
    /*    margin: 0 20px;*/
    ::v-deep .el-table {
        .el-table__body-wrapper {
            .el-table__row {
                .ranking {
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    font-weight: bold;
                    background-size: cover;
                    display: inline-block;
                    font-style: italic;
                }
                &:first-child {
                    .ranking {
                        background: url("../../../../assets/images/student/num-001.png") no-repeat center;
                    }
                }
                &:nth-of-type(2) {
                    .ranking {
                        background: url("../../../../assets/images/student/num-002.png") no-repeat center;
                    }
                }
                &:nth-of-type(3) {
                    .ranking {
                        background: url("../../../../assets/images/student/num-003.png") no-repeat center;
                    }
                }
                .ranking-general {
                    line-height: 24px;
                    width: 24px;
                    height: 24px;
                    background: #E7F6EF;
                    border-radius: 50%;
                    display: inline-block;
                    text-align: center;
                }
            }
        }
    }
    /*}*/
}
.big-title {
    font-size: 20px;
    line-height: 1;
    margin: 20px 40px;
    font-weight: 700;
   
color: #343434;

}
.area-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .percentage {
        width: 50px;
        text-align: right;
        white-space: nowrap;
        margin-left: 10px;
    }
    .area-process {
        width: 1%;
        flex: 1;
    }
}
.operation-pagination {
    padding: 0;
    margin: 20px 0;
    text-align: center;
}
